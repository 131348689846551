<template>
  <div class="nav idx-nav">
    <div class="navCenterDec"></div>
    <div class="mb_gotop" v-if="scY > 100" @click="scrollGoTop()">
      <i class="icon icon-arrowtop"></i>
    </div>
    <template v-for="(item, index) in IdxFmenuList">
      <div :class="[item.class, {'active': item.to === isActive} ]" v-if="isLogShow(item.click)">
        <div class="m_event-box" :class="[{'close_x': item.close === isClose}, {'close_x2': item.stop === isClose2}]">
          <b-button :href="item.url" :class="[item.link]" target="_blank" @click="toggleMenuModal(item.needLogin, item.to, item.click)">
              <i :class="item.icon"></i>
              <span>{{ $t(item.name) }}</span>
          </b-button>
          <a v-if="item.close" class="close_money" @click.stop="giveMoney"></a>
          <span v-if="item.stop" class="close_money2" @click.stop="giveisClose2"></span>
        </div>
      </div>

      
    </template>
    <div v-show="ShowDownload" :class="[ShowDownload.class]">
      <div class="m_event-box" :class="[{'close_x': ShowDownload.close === isClose}, {'close_x2': ShowDownload.stop === isClose2}]">
        <b-button :href="DownloadUrl" :class="[ShowDownload.link]">
            <i :class="ShowDownload.icon"></i>
            <span>{{ $t(ShowDownload.name) }}</span>
        </b-button>
        <a v-if="ShowDownload.close" class="close_money" @click.stop="giveMoney"></a>
        <span v-if="ShowDownload.stop" class="close_money2" @click.stop="giveisClose2"></span>
      </div>
    </div>

    <dialogCSR v-model="isShow" />

  </div>
</template>

<script>
  var idxFmenu = [];
  try { idxFmenu = require("$json/idxFmenu.json"); } catch (e) {}
  const dialogCSR = require(`~/components/mobile/${process.env.M_dialogCSR}.vue`).default;
  import { mapState, mapMutations, mapActions, mapGetters } from "vuex";
  export default {
    components: {
      dialogCSR
    },
    data() {
      return {
        idxFmenu,
        scTimer: 0,
        scY: 0,
        isActive: 0,
        isClose: 0,
        isClose2: 0,
        isShow: 0,
        isAdVisible: true
      };
    },
    methods: {
      giveisClose2(){
        this.isClose2 = !this.isClose2;
      },
      giveMoney(){
        this.isClose = !this.isClose;
      },
      isLogShow(item){
        if(item != "toggleRmenu")
          return true;

        return this.$auth.$state.loggedIn;
      },
      handleScroll(){
        if (this.scTimer) return;
        this.scTimer = setTimeout(() => {
          this.scY = window.scrollY;
          clearTimeout(this.scTimer);
          this.scTimer = 0;
        }, 100);
      },
      scrollGoTop(){
        window.scrollTo({ top: 0 });
      },
      service(){
        this.isShow++;
      },
      service_circle(){
        this.isShow++;
      },
      toggleExchPoint() {
        this.$root.$emit("bv::toggle::modal", "modalExchPoint", "#btnToggle");
      },
      toggleRmenu() {
        this.$root.$emit("bv::toggle::modal", "bv-modal-example", "#myModalRef");
      },
      toggleRmenu2() {
        this.$root.$emit("bv::toggle::modal", "bv-modal-example", "#myModalRef");
      },
      toggleTransfer() {
            this.$root.$emit("bv::toggle::modal", "modalTransferPoint", "#btnToggle");
      },
      toggleMenuModal(status, path, click) {
        if (this.$route.name == path)
          return;

        if (status && this.$route.name == "mobile-account-login")
          return;


        if (status && this.$auth.$state.loggedIn === false) {
          let { name, params, query } = {};
          this.$router.push({ name: 'mobile-account-login', params, query });
          return;
        }

        if (path && path != "") {
          let { name, params, query } = {};
          this.$router.push({ name: path, params, query });
          this.isActive = path;
          return;
        }

        switch (click) {
          case 'toggleTransfer':
            this.toggleTransfer();
            break;
          case 'toggleExchPoint':
            this.toggleExchPoint();
            break;
          case 'service':
            this.service();
            break;
          case 'service_circle':
            this.service_circle();
            break;
          case 'toggleRmenu':
            this.toggleRmenu();
            break;
          case 'toggleRmenu2':
            this.toggleRmenu2();
            break;
        }
      },
    },
    mounted() {
      this.isActive = this.$route.name;
      window.addEventListener('scroll', this.handleScroll);
    },
    watch: {
    },
    computed: {
      DEF_webStyle() {
        return process.env.DEF_webStyle || 'default';
      },
      IdxFmenuList() {
        return this.idxFmenu.filter((Item) => {
          return Item.to != 'mobile-download-APP';
        }).map((Item) => {
          return Item;
        });
      },
      ShowDownload() {
        let Data = this.idxFmenu.find((Item) => Item.to == 'mobile-download-APP');
        if (!Data)
          return false;

        if (typeof window == 'undefined' || Object.keys(this.DeviceType).indexOf('type') < 0)
            return false;

        if (this.ShowDownloadApp && this.DeviceType.type == 'ios')
          return Data;

        if (this.ShowDownloadApk && this.DeviceType.type == 'android')
          return Data;

        return false;
      },
      DownloadUrl() {
        if (typeof window == 'undefined' || Object.keys(this.DeviceType).indexOf('type') < 0)
          return '/';

        if (this.ShowDownloadApp && this.DeviceType.type == 'ios') {
          let Code        = '';
          let AgentCode   = this.$store.state.UpAgentCode;
          let CookiesCode = this.$cookies.get("UpAgentCode");
          let StorageCode = localStorage.getItem('UpAgentCode');

          if (StorageCode && StorageCode != null && StorageCode != '')
            Code = StorageCode;
          if (CookiesCode && CookiesCode != null && CookiesCode != '')
            Code = CookiesCode;
          if (AgentCode && AgentCode != null && AgentCode != '')
            Code = AgentCode;

          let DownloadUrl = window.location.protocol + '//' + window.location.host + '/download/APP' + (Code != '' ? '?a=' + Code : '');
          return DownloadUrl;
        }

        if (this.ShowDownloadApk && this.DeviceType.type == 'android') {
          return window.location.protocol + '//' + window.location.host + this.GetDownloadApkUrl;
        }
        
        return '/';
      },
      ...mapState(['DeviceType']),
      ...mapGetters('webSetting', ['ShowDownloadApp', 'ShowDownloadApk', 'GetDownloadApkUrl'])
    },
  };
</script>
