<template>
  <!-- 跑馬燈 -->
  <div class="idxMar">
    <div class="marquee" @click="toggleModal" ref="btnToggle" v-b-modal.modal-center>

      <div class="icon icon-new"></div>
      <div class="mq-cont as1">
        <p :style="`animation-duration: ${seconds}s; -webkit-animation-duration: ${seconds}s;`">
          {{ AnnouncementStr }}
        </p>
      </div>
    </div>
    <div>
      <b-modal id="modalInfo" ref="myModalRef" centered :modal-class="ClassName" hide-footer>
        <template #modal-title> {{ $t("store.announcement") }} </template>

        <div class="mtb-panel" @click="audioClick">
          <div class="mtb-nav" v-for="item in tabData" :key="item.id" :class="{'active': (current === item.id)}" @click="change(item.id)">
            <span class="mtbn-title">{{ item.title }}</span>
            <span class="mtbn-data">{{ item.date | useLocaleDate($i18n.locale) }}</span>
            <!--<transition name="accordion" appear>
              <div class="mtbn-content" v-show="current === item.id">
                <span>{{ item.content }}</span>
              </div>
            </transition>-->
            <transition mode="out-in" name="accordion" @before-enter="beforeEnter" @enter="enter">
              <div class="mtbn-content" v-show="current === item.id">
                <span v-html="replaceStr(item.content)"/>
              </div>
            </transition>
          </div>
        </div>
        <b-button variant="submit" block @click="hideModal(); audioClick()">{{ $t("store.btn.close") }}</b-button>
      </b-modal>
    </div>

  </div>
</template>

<script>
  import { mapState, mapMutations, mapActions, mapGetters } from "vuex";

  export default {
    name: "idxMarquee",
    data() {
      return {
        first: true,
        myclass: ["dialog-noticemb"],
        seconds: 20,
        current: -10,
        tabData: []
      };
    },
    methods: {
      audioClick() {
        if(this.DEF_webStyle === 'jdf'|| this.DEF_webStyle === 'fl8n' || this.DEF_webStyle === 'fl88' || this.DEF_webStyle === 'kb88' || this.DEF_webStyle === 'jh8'){
          if(this.IsAudio) {
            const audio = document.querySelector("#beep");
            const context = new AudioContext();
            function isIOS() {
              return /iPhone|iPad|iPod/i.test(navigator.userAgent);
            };
            if (context.state === 'suspended') {
              context.resume().then(() => {});
            };
            if (isIOS()) {
              setTimeout(function() {
                audio.muted = false;
                audio.play();
              }, 200);
            } else {
              audio.muted = false;
              audio.play();
            };
          }
        };
      },
      change(item){
        if (item === this.current) {
          return this.current = -10;
        } else {
          return this.current = item;
        }
      },
      beforeEnter(x){
        x.style.display = "block";
        x.style.height = "null";
        x.myHeight = x.offsetHeight;
        x.style.height = 0;
        x.style.display = null;
      },
      enter(x){
        x.style.height = x.myHeight + "px";
      },
      showModal: function () {
        this.$refs.myModalRef.show();
      },
      hideModal() {
        this.$refs.myModalRef.hide();
      },
      toggleModal() {
        this.$root.$emit("bv::toggle::modal", "modalInfo", "#btnToggle");
      },
      noticeOK(){
        return this.Announcement.map(function(value, i){
          return {
            id: i,
            date:  value.date,
            title: value.title,
            content : value.content,
            created_at : value.created_at,
          }
        });
      },
      getData() {
        this._getAnnouncement().then(() => {
          this.setSeconds();
          this.tabData = this.noticeOK();
        });
      },
      setSeconds() {
        if (!this.Announcement || this.Announcement.length == 0)
          return;

        let num = this.Announcement.map((item) => item.content).join("&nbsp;&nbsp;&nbsp;&nbsp;").replace(/\s*/g,"").length;
        let s   = parseInt(num / 200) + 1;
        this.seconds = s * (this.DEF_webStyle == 'fl88' ? 45 : 30);
      },
      replaceStr(content) {
        if (content)
          return content.replace(/\n/g,'<br/>').replace(/ /g,' &nbsp')
        else
          return '';
      },
      ...mapActions(["_getAnnouncement"])
    },
    mounted() {
      this.getData();
      this.$root.$on('bv::modal::show', (bvEvent, modalId) => {
        this.tabData = this.noticeOK();
      })
    },
    watch: {
      Announcement() {
        this.setSeconds();
        this.tabData = this.noticeOK();
      },
    },
    computed: {
      AnnouncementStr() {
        if (!this.Announcement || this.Announcement.length == 0)
          return '';

        return this.Announcement.map((item) => item.content).join("            ");
      },
      DEF_webStyle() {
        return process.env.DEF_webStyle || 'default';
      },
      ClassName() {
        let ModalClass = { 'dialog-noticemb': true };
        if (!this.FestivalClass)
          return ModalClass;

        return Object.assign(ModalClass, this.FestivalClass);
      },
      ...mapState(['WebSetting', 'Announcement', 'IsAudio']),
      ...mapGetters('webSetting', ['FestivalClass'])
    },
  };
</script>
