<template>
  <nuxt-link :to="value.to" :class="value.class">
    <i class="icon" :class="value.icon"></i>
    <span>{{ value.txt }}</span>
    <template v-if="value.icon == 'icon-mail'">
      <span v-if="UnReadCount > 0" class="badge badge-pill">
        {{ UnReadCount }}
      </span>
    </template>
  </nuxt-link>
</template>

<script>
import { mapState } from "vuex";
export default {
  name: "modHideLink",
  props: {
    value: Object,
  },
  methods: {
  },
  computed: {
    ...mapState("webSetting", ["UnReadCount"]),
  }
};
</script>