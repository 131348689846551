<template>
  <div v-if="ShowAutoPoint" class="walletTransferBtn">
    <b-form-checkbox switch size="lg" v-model="AutoTransfer"></b-form-checkbox>
    <div class="text">{{ $t("store.auto_transfer") }}</div>
  </div>
</template>

<script>
import { mapState, mapGetters, mapActions } from "vuex";
export default {
    components:{
    },
    data(){
      return{
        AutoTransfer: false,
        AutoLoading: false
      }
    },
    methods: {
      ...mapActions("account", ['ChangePointModel']),
    },
    mounted: function () {
      this.AutoTransfer = this.UserPointModel;
    },
    watch: {
      AutoTransfer(Value) {
        if (Value == this.UserPointModel)
          return;
        if (this.AutoLoading)
          return;
        this.AutoLoading = true;
        this.ChangePointModel({ status: Value ? 1 : 0 }).then(() => {
          this.AutoLoading = false;
          return this.$auth.fetchUser();
        });
      },
    },
    computed: {
      UserPointModel() {
        if (!this.$auth.$state.loggedIn)
        return false;
        return this.$auth.user.point_model != 'manual';
      },
      ...mapState(['WebSetting']),
      ...mapGetters("webSetting", ["ShowAutoPoint"]),
    }
  }
</script>
