<template>
  <div class="mbtnin-msg" v-if="$auth.$state.loggedIn === true">
      <div class="mbtnin-left">
        <div class="mtop-cont">
          <q class="rank" v-if="ShowLevel && GroupName">
          <i class="icon icon-rank"></i>
          {{$auth.$state.user.group_name}}</q>
          <span>{{ UserName }}</span>
          <span>{{ $t("store.hotpro_hello") }}</span>
        </div>
        <div class="mtop-cont">
          <b-spinner variant="variant" type="grow" small v-if="AccountWalletMainLoading" label="Spinning"></b-spinner>
          <span v-else>${{ AccountWalletMain | currencyInt }}</span>
          <button class="btn-last" @click="RefreshAccountWallet">
            <i class="icon icon-refresh"></i>
            <span>{{ this.$t("store.user.coin") }}</span>
          </button>
        </div>
      </div>
  </div>
</template>

<script>
import axios from "axios";
import { mapActions, mapGetters, mapState } from "vuex";
export default {
  name: "modHideMsg",
  data() {
      return {
        AccountWalletMain: 0,
        AccountWalletMainLoading: false,
      };
    },
  methods: {
    RefreshAccountWallet() {
      this.GetAccountWallet(0);
    },
    OnAccountWalletChanged() {
      this.AccountWalletMain = this.AccountWallet[0];
    },
    OnAccountWalletLoadingChanged() {
      this.AccountWalletMainLoading = this.AccountWalletLoading[0];
    },
      ...mapActions("account", ["GetAccountWallet"]),
  },
  created() {
      if (Object.keys(this.AccountWallet).indexOf("0") >= 0)
        this.OnAccountWalletChanged();
      if (Object.keys(this.AccountWalletLoading).indexOf("0") >= 0)
        this.OnAccountWalletLoadingChanged();

      this.$watch(() => this.AccountWallet[0], this.OnAccountWalletChanged);
      this.$watch(
        () => this.AccountWalletLoading[0],
        this.OnAccountWalletLoadingChanged
      );
    },
  computed: {
    UserName() {
      if (this.WebSetting.MasterAccount === "account")
        return this.$auth.$state.user.account;
      else
        return this.$auth.$state.user.mobile || this.$auth.$state.user.account;
    },
    GroupName() {
      if (this.$auth.$state.user.group_name && this.$auth.$state.user.group_name != "")
        return this.$auth.$state.user.group_name;
      else
        return false;
    },
    ShowLevel() {
      return this.WebSetting.SHOW_LEVEL === "1";
    },
    ...mapState(['WebSetting']),
    ...mapGetters("account", ["AccountWallet", "AccountWalletLoading"]),
  }
};
</script>