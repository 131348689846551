<template>
  <div class="serbigbox dn sound_on" id="serbigboxId" :class="{show: show}" @click="service_circle">
    <div class="sersmallbox">
      <a v-for="(item, index) in items" :key="index" :href="item.link" target="_blank" :data-text="$t(item.text)" :class="`btn-${item.platform}`"  :style="item.filterStyle">
        <span :style="item.spanStyle">
          <i :class="`icon icon-${item.platform}`"></i>
        </span>
      </a>
    </div>
  </div>
</template>

<script>
  import { mapState } from "vuex";
  export default {
    props: {
      value: Number,
    },
    data() {
      return {
        show: false,
        count: 1,
        items: []
      };
    },
    methods: {
      itemsData(){
        this.items = [];
        if(this.HasLivechat){
          this.items.push(
            {
              link: this.$store.state.WebSetting.LIVECHAT,
              text: "store.customer.service_tw",
              platform: "service",
              filterStyle: "",
              spanStyle: ""
            }
          )
        }
        if(this.HasLineLink){
          this.items.push(
            {
              link: this.$store.state.WebSetting.LINELINK,
              text: "store.customer.service_line",
              platform: "line",
              filterStyle: "",
              spanStyle: ""
            }
          )
        }
        if(this.HasTelegram){
          this.items.push(
            {
              link: this.$store.state.WebSetting.TELRGRAM,
              text: "store.customer.telegram",
              platform: "telegram",
              filterStyle: "",
              spanStyle: ""
            }
          )
        }
        if(this.HasFacebook){
          this.items.push(
            {
              link: this.$store.state.WebSetting.FACEBOOK,
              text: "store.customer.facebook",
              platform: "facebook",
              filterStyle: "",
              spanStyle: ""
            }
          )
        }
        if(this.HasTwitter){
          this.items.push(
            {
              link: this.$store.state.WebSetting.TWITTER,
              text: "store.customer.twitter",
              platform: "twitter",
              filterStyle: "",
              spanStyle: ""
            }
          )
        }
        if(this.HasInstagram){
          this.items.push(
            {
              link: this.$store.state.WebSetting.INSTAGRAM,
              text: "store.customer.instagram",
              platform: "instagram",
              filterStyle: "",
              spanStyle: ""
            }
          )
        }
        if(this.HasWhatsApp){
          this.items.push(
            {
              link: this.$store.state.WebSetting.WHATSAPP,
              text: "store.customer.whatsapp",
              platform: "whatsapp",
              filterStyle: "",
              spanStyle: ""
            }
          )
        }
        if(this.HasNewIn){
          this.items.push(
            {
              link: this.$store.state.WebSetting.NEWIN,
              text: "store.customer.service_tw",
              platform: "newin",
              filterStyle: "",
              spanStyle: ""
            }
          )
        }
      },
      service_circle(){
        this.show = !this.show;
        this.itemsAnimate();
      },
      itemsAnimate(){
        for(var i = 0; i < this.items.length; i++){
            let angle = (360/this.count * (i+1))+90;
            if( this.show == true){
              this.items[i].filterStyle = {
                transitionDelay : 0.05* i + "s",
                transform: "rotate("+angle+"deg) translateX(40px)"
              }
              this.items[i].spanStyle = {
                transform: "rotate( -"+angle+"deg)"
              }
            }else {
              this.items[i].filterStyle = "";
              this.items[i].spanStyle = "";
            }
          }
      },
    },
    mounted(){
      this.itemsData();
      this.count = this.items.length;
    },
    computed: {
      HasLineLink(){
        return Object.keys(this.WebSetting).indexOf('LINELINK') && this.WebSetting.LINELINK;
      },
      HasLivechat(){
        return Object.keys(this.WebSetting).indexOf('LIVECHAT') && this.WebSetting.LIVECHAT;
      },
      HasTelegram(){
        return Object.keys(this.WebSetting).indexOf('TELRGRAM') && this.WebSetting.TELRGRAM;
      },
      HasInstagram(){
        return Object.keys(this.WebSetting).indexOf('INSTAGRAM') && this.WebSetting.INSTAGRAM;
      },
      HasTwitter(){
        return Object.keys(this.WebSetting).indexOf('TWITTER') && this.WebSetting.TWITTER;
      },
      HasFacebook(){
        return Object.keys(this.WebSetting).indexOf('FACEBOOK') && this.WebSetting.FACEBOOK;
      },
      HasWhatsApp(){
        return Object.keys(this.WebSetting).indexOf('WHATSAPP') && this.WebSetting.WHATSAPP;
      },
      HasNewIn(){
        return Object.keys(this.WebSetting).indexOf('NEWIN') && this.WebSetting.NEWIN;
      },
      ...mapState(["WebSetting"]),

    },
    watch:{
      value(value){
        this.service_circle();
      }
    }
  };
</script>
