<template>
  <!-- 使用者帳號 -->
  <div class="user-info-wrap">
    <div class="user-number">
      <nuxt-link to="/mobile/user/profile">
        <i class="icon icon-user"></i>
      </nuxt-link>
      <q><span>{{ UserName }}</span></q>
      <template v-if="DEF_webStyle !== 'bu88'">
        <div v-if="ShowLevel && GroupName" class="memberLevel">
          <i class="icon icon-rank"></i>
          <q>{{ $auth.$state.user.group_name }}</q>
        </div>
      </template>
    </div>
    <!-- 使用者餘額 -->
    <div class="user-wallet">
      <i class="icon icon-deposit"></i>
      <q>
        <b-spinner
          variant="variant"
          type="grow"
          small
          v-if="AccountWalletMainLoading"
          label="Spinning"></b-spinner>
        <span v-else>{{ AccountWalletMain | currencyInt }}</span>
        </q>
      <template v-if="DEF_webStyle !== 'sgph'">
        <i class="icon icon-refresh" @click="RefreshAccountWallet"></i>
      </template>

      <template v-if="DEF_webStyle === 'sgph'">
        <allBackWalletBtn />
      </template>
    </div>
  </div>
</template>
<script>
import { mapState, mapActions, mapGetters } from "vuex";
import allBackWalletBtn from "~/components/desktop/allBackWalletBtn.vue";

export default {
  layout: "mobile",
  components: {
    allBackWalletBtn
  },
  data() {
    return {
      Refresh: null,
      AccountWalletMain: 0,
      AccountWalletMainLoading: false,
    };
  },
  methods: {
    OnLogout() {
      this.$auth.logout("local");
    },
    RefreshAccountWallet() {
      this.GetAccountWallet(0);
    },
    SetAutoRefresh() {
      if (this.Refresh) clearInterval(this.Refresh);
      this.Refresh = setInterval(() => {
        if (this.$auth.$state.loggedIn === true) this.getNureadNotice();
      }, 60000);
    },
    OnAccountWalletChanged() {
      this.AccountWalletMain = this.AccountWallet[0];
    },
    OnAccountWalletLoadingChanged() {
      this.AccountWalletMainLoading = this.AccountWalletLoading[0];
    },
    ...mapActions("account", ["GetAccountWallet"]),
    ...mapActions("webSetting", ["getNureadNotice"]),
  },
  created() {

    if (Object.keys(this.AccountWallet).indexOf("0") >= 0)
      this.OnAccountWalletChanged();
    if (Object.keys(this.AccountWalletLoading).indexOf("0") >= 0)
      this.OnAccountWalletLoadingChanged();

    this.$watch(() => this.AccountWallet[0], this.OnAccountWalletChanged);
    this.$watch(
      () => this.AccountWalletLoading[0],
      this.OnAccountWalletLoadingChanged
    );
  },
  beforeDestroy() {
    clearInterval(this.Refresh);
  },
  watch: {
    "$auth.$state.loggedIn"(loggedIn) {
      // console.log(loggedIn)
    },
    "$auth.$state.user"(user) {
      // console.log(user)
    },
  },
  computed: {
    GroupName() {
      if (this.$auth.$state.user.group_name && this.$auth.$state.user.group_name != "")
        return this.$auth.$state.user.group_name;
      else
        return false;
    },
    UserName() {
      /*
      if (this.WebSetting.MasterAccount === "account")
      */
        return this.$auth.$state.user.account;
      /*
      else
        return this.$auth.$state.user.mobile || this.$auth.$state.user.account;
      */
    },
    ShowLevel() {
      return this.WebSetting.SHOW_LEVEL === "1";
    },
    DEF_webStyle() {
      return process.env.DEF_webStyle || 'default';
    },
    ...mapState(["WebSetting"]),
    ...mapGetters(["Meun"]),
    ...mapState("account", ["AccountWallet", "AccountWalletLoading"]),
    ...mapState("webSetting", ["UnReadCount"]),
  },
};
</script>
