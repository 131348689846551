<template>
    <div class="sm_wrap sound_on">
      <template v-if="type == 'tab'">
        <div class="sm_menu">
          <div
            v-for="(item ,index) in idxMenuData"
            :key="index"
            v-show="item.content.length > 0"
            :class="{ sm_item: true, active: index === now }"
            @click="change(index)"
          >
            <i :class="item.class"></i>
            <span>{{ item.tab }}</span>
          </div>
        </div>
        <div
          v-for="(item ,index) in idxMenuData"
          :key="index"
          :class="{ sm_content: true, active: index === now }"
        >
          <nuxt-link class="sm_item" v-for="(box ,index) in item.content" :key="index" :to="box.to" @click.native="hideModal" v-show="box.show">
            <i :class="box.class"></i>
            <span>{{ box.name }}</span>
          </nuxt-link>
        </div>
      </template>

      <template v-if="type=='dropdown'">
        <div class="sm_wrap">
          <div class="sm_menu">
            <nuxt-link class="sm_item" to="/mobile" @click.native="hideModal">
                <i class="icon icon-home"></i>
                <span>{{ this.$t("store.home") }}</span>
            </nuxt-link>

            <div class="sm_item" v-for="(item ,index) in dropDownData" :key="index" :class="{'active': (current === item.id)}" @click="dropchange(item.id)" v-if="!item.need || $auth.$state.loggedIn === true">
              <template  v-if="item.content.length === 1">
                <nuxt-link :to="item.oncelink" class="sm_group no_arr" @click.native="hideModal">
                  <i :class="item.class"></i>
                  <span>{{ item.tab }}</span>
                </nuxt-link>
              </template>
              <template v-else>
                <div class="sm_group" >
                  <i :class="item.class"></i>
                  <span>{{ item.tab }}</span>
                </div>
                <div class="sm_content">
                  <nuxt-link class="sm_item" v-for="(box ,index) in item.content" :key="index" :to="box.to" @click.native="hideModal" v-if="box.show">
                    <i :class="box.class"></i>
                    <span>{{ box.name }}</span>
                  </nuxt-link>
                  <a href="https://blog.fale88.com/" class="dn sm_item" v-if="DEF_webStyle === 'fl88'" target="_blank">
                    <i class="icon icon-newspaper"></i>
                    <span>{{ $t("store.blog") }}</span>
                  </a>
                </div>
              </template>
            </div>
          </div>
        </div>
      </template>

      <template v-if="type=='help'">
        <div class="title">{{ $t("store.using_help") }}</div>
        <ul class="sm_help m_helpbox">
          <template v-if="$auth.$state.loggedIn === true">
            <li class="sm_list" v-if="DEF_webStyle === 'jdf'">
              <a href="#" class="sm_item">
                <span>{{ $t("store.hotpro_switch") }}</span>
                <b-form-checkbox switch size="lg" v-model="checked"></b-form-checkbox>
              </a>
            </li>
          </template>
          <template v-for="(item, index) in IdxFooterList">
            <template v-if="item.url == '/download/APP'">
              <li v-show="ShowDownload" class="sm_list" :class="item.li">
                <a target="_blank" :href="DownloadUrl" class="sm_item">
                  <i :class="item.class"></i>
                  <p>{{ $t(item.name) }}</p>
                </a>
              </li>
            </template>
            <template v-else>
              <li class="sm_list" :class="item.li">
                <a v-if="item.blank" target="_blank" :href="item.url" class="sm_item">
                  <i :class="item.class"></i>
                  <p>{{ $t(item.name) }}</p>
                </a>
                <nuxt-link v-else :to="`/mobile${item.url}`" @click.native="hideModal" class="sm_item no_arr">
                  <i :class="item.class"></i>
                  <p>{{ $t(item.name) }}</p>
                </nuxt-link>
              </li>
            </template>
          </template>
        </ul>
      </template>

      <!-- <audio id="beep" src="/audio/btnBack.mp3" type="audio/mp3" muted></audio> -->

    </div>
</template>
<script>
  import { mapState, mapMutations, mapGetters } from "vuex";
  var idxFooter = [];
  try { idxFooter = require("$json/idxFooter.json"); } catch (e) {}
  export default {
    name: "modHideMain",
    props: {
        type: String
    },
    data(){
      return{
        idxFooter,
        idxMenuData: this.idxMenuList(),
        dropDownData: [],
        current: -10,
        isLeft: false,
        checked: true,
        now: 0
      }
    },
    methods: {
      dropchange(item){
        if (item === this.current) {
          return this.current = -10;
        } else {
          return this.current = item;
        }
      },
      change(index){
        this.now = index;
      },
      hideModal() {
        this.$root.$emit('bv::hide::modal', 'bv-modal-example', '#myModalRef')
      },
      dropDownList(){
        let dropDownBtn = [
          {
            id: 1,
            tab: this.$t("store.hotpro_title"),
            class: "icon icon-list",
            isActive : false,
            need: false,
            oncelink:  "/mobile/game/sport",
            content: [
              {
                to: "/mobile/game/sport",
                class: "icon icon-football",
                name: this.$t("store.game_sport"),
                show: this.ShowSport
              },
              {
                to: "/mobile/game/live",
                class: "icon icon-dice",
                name: this.$t("store.game_live"),
                show: this.ShowLive
              },
              {
                to: "/mobile/game/egame",
                class: "icon icon-slot",
                name: this.$t("store.game_egame"),
                show: this.ShowEGame
              },
              {
                to: "/mobile/game/lottery",
                class: "icon icon-lotto",
                name: this.$t("store.game_lotto"),
                show: this.ShowLottery
              },
              {
                to: "/mobile/game/chess",
                class: "icon icon-poker",
                name: this.$t("store.game_chess"),
                show: this.ShowChess
              },
              {
                to: "/mobile/game/fish",
                class: "icon icon-fish",
                name: this.$t("store.game_fish"),
                show: this.ShowFish
              },
              {
                to: "/mobile/game/animal",
                class: "icon icon-animal",
                name: this.$t("store.game_animal"),
                show: this.ShowAnimal
              }
            ]
          },
          {
            id: 2,
            tab: this.$t("store.user.profile"),
            class: "icon icon-user",
            isActive : false,
            need: true,
            oncelink:  "/mobile/user/profile",
            content: [
              {
                to: "/mobile/user/profile",
                class: "icon icon-user",
                name: this.$t("store.profile"),
                show: true
              },
              {
                to: "/mobile/user/user",
                class: "icon icon-usercard",
                name: this.$t("store.user_approve"),
                show:  this.ShowAccountCard
              },
              {
                to: "/mobile/user/card",
                class: "icon icon-card",
                name: this.$t("store.card_manage"),
                show: true
              },
              {
                to: "/mobile/user/virtual",
                class: "icon icon-altcoin",
                name: this.$t("store.virtual.tied_crypto"),
                show: this.ShowVirtualCurrency
              },
              {
                to: "/mobile/user/payment",
                class: "icon icon-mobile1",
                name: this.$t("store.pay.payment"),
                show: this.ShowPaySetting
              }
            ]
          },
          {
            id: 3,
            tab: this.$t("store.user_hisSearch"),
            class: "icon icon-history",
            isActive: false,
            need: true,
            oncelink:  "/mobile/history/hisBet",
            content:[
              {
                to: "/mobile/history/hisBet",
                class: "icon icon-game",
                name: this.$t("store.hisbetReport"),
                show: true
              },
              {
                to: "/mobile/history/hisDeposit",
                class: "icon icon-deposit",
                name: this.$t("store.desopit.deposit_records"),
                show: true
              },
              {
                to: "/mobile/history/hisSale",
                class: "icon icon-diamond",
                name: this.$t("store.withdrawal.treasure_records"),
                show: true
              },
              {
                to: "/mobile/history/hisActivity",
                class: "icon icon-stack",
                name: this.$t("store.discount.records"),
                show: true
              },
              {
                to: "/mobile/history/hisExchPoint",
                class: "icon icon-point",
                name: this.$t("store.transfer.point_records"),
                show: true
              },
              {
                to: "/mobile/history/hisTransfer",
                class: "icon icon-star",
                name: this.$t("store.user_histransfer"),
                show: this.ShowConversion
              }
            ]
          },
          {
            id: 4,
            tab: this.$t("store.share"),
            class: "icon icon-moneybag",
            isActive: false,
            need: true,
            oncelink:  "/mobile/share/share",
            content:[
              {
                to: "/mobile/share/share",
                class: "icon icon-moneybag",
                name: this.$t("store.share"),
                show:  this.ShowShare
              },
              {
                to: "/mobile/share/hisShare",
                class: "icon icon-share",
                name: this.$t("store.share_money"),
                show:  this.ShowShare
              }
            ]
          },
          {
            id: 5,
            tab: this.$t("store.pw.user_chgpw"),
            class: "icon icon-key",
            isActive: false,
            need: true,
            oncelink:  "/mobile/password/chgpw",
            content:[
              {
                to: "/mobile/password/chgpw",
                class: "icon icon-key",
                name: this.$t("store.pw.user_chgpw"),
                show: true
              },
              {
                to: "/mobile/password/chgsafepw",
                class: "icon icon-lock",
                name: this.$t("store.pw.chgsafepw"),
                show: this.ShowWithdrawCode
              }
            ]
          }
        ];
        dropDownBtn.forEach(function (item, index) {
          dropDownBtn[index].content = item.content.filter(function (item) {
            return item.show == true;
          });
        });
        return dropDownBtn;
      },
      idxMenuList(){
        let idxMenuBtn = [
          {
            tab: this.$t("store.user.profile"),
            class: "icon icon-user",
            content: [
              {
                to: "/mobile/user/profile",
                class: "icon icon-smile",
                name: this.$t("store.profile"),
                show: true
              },
              {
                to: "/mobile/user/user",
                class: "icon icon-usercard",
                name: this.$t("store.user_approve"),
                show:  this.ShowAccountCard
              },
              {
                to: "/mobile/user/card",
                class: "icon icon-card",
                name: this.$t("store.card_manage"),
                show: true
              },
              {
                to: "/mobile/user/virtual",
                class: "icon icon-altcoin",
                name: this.$t("store.virtual.tied_crypto"),
                show: this.ShowVirtualCurrency
              },
              {
                to: "/mobile/user/payment",
                class: "icon icon-mobile1",
                name: this.$t("store.pay.payment"),
                show: this.ShowPaySetting
              }
            ]
          },
          {
            tab: this.$t("store.user_hisSearch"),
            class: "icon icon-history",
            content:[
              {
                to: "/mobile/history/hisBet",
                class: "icon icon-game",
                name: this.$t("store.hisbetReport"),
                show: true
              },
              {
                to: "/mobile/history/hisDeposit",
                class: "icon icon-deposit",
                name: this.$t("store.desopit.deposit_records"),
                show: true
              },
              {
                to: "/mobile/history/hisSale",
                class: "icon icon-diamond",
                name: this.$t("store.withdrawal.treasure_records"),
                show: true
              },
              {
                to: "/mobile/history/hisActivity",
                class: "icon icon-stack",
                name: this.$t("store.discount.records"),
                show: true
              },
              {
                to: "/mobile/history/hisExchPoint",
                class: "icon icon-loop2",
                name: this.$t("store.transfer.point_records"),
                show: true
              },
              {
                to: "/mobile/history/hisTransfer",
                class: "icon icon-star-full",
                name: this.$t("store.user_histransfer"),
                show: this.ShowConversion
              }
            ]
          },
          {
            tab: this.$t("store.share"),
            class: "icon icon-moneybag",
            content:[
              {
                to: "/mobile/share/share",
                class: "icon icon-moneybag",
                name: this.$t("store.share"),
                show:  this.ShowShare
              },
              {
                to: "/mobile/share/hisShare",
                class: "icon icon-share",
                name: this.$t("store.share_money"),
                show:  this.ShowShare
              }
            ]
          },
          {
            tab: this.$t("store.pw.user_chgpw"),
            class: "icon icon-key",
            content:[
              {
                to: "/mobile/password/chgpw",
                class: "icon icon-key",
                name: this.$t("store.pw.user_chgpw"),
                show: true
              },
              {
                to: "/mobile/password/chgsafepw",
                class: "icon icon-lock",
                name: this.$t("store.pw.chgsafepw"),
                show: this.ShowWithdrawCode
              }
            ]
          }
        ];

        return idxMenuBtn.map((item) => {
          item.content = item.content.filter(function (value) {
            return value.show == true;
          });
          return item;
        });
      },
      changeLang() {
        this.dropDownData = this.dropDownList();
        this.idxMenuData = this.idxMenuList();
      },
      ...mapMutations(['_setIsLeft']),
    },
    created() {
      this.checked = this.IsLeft;
    },
    mounted() {
      this.changeLang();
      let RoutePath = this.$route.path;
      this.idxMenuData.forEach((Item, Index) => {
        let NowPath = Item.content.find(Value => Value.to == RoutePath);
        if (NowPath)
          this.now = Index;
      });

      if (this.DEF_webStyle === 'jdf' || this.DEF_webStyle === 'fl8n' || this.DEF_webStyle === 'fl88' || this.DEF_webStyle === 'jh8')
      {
        if(this.IsAudio)
        {
          try {
            const btnLinks = document.querySelectorAll("button, a, ul.navbar, .game_leftbtn, .gamelibox, .fab_close, .mq-cont.as1, .sm_item");
            const audio    = document.querySelector("#beep");
            const context  = new AudioContext();
            function isIOS() {
              return /iPhone|iPad|iPod/i.test(navigator.userAgent);
            }
            btnLinks.forEach((btnLink) => {
              btnLink.addEventListener("click", () => {
                if (context.state === 'suspended') {
                  context.resume().then(() => {});
                };
                if (isIOS()) {
                  setTimeout(function() {
                    audio.muted = false;
                    audio.play();
                  }, 200);
                } else {
                  audio.muted = false;
                  audio.play();
                };
              });
            });
          } catch (e) {
            console.log(e);
          }
        }
      };
    },
    watch: {
      checked (value) {
        if (value == this.IsLeft)
          return ;

        this._setIsLeft(value);
      },
      dropDownList(){
        this.dropDownData = this.dropDownList();
      },
      idxMenuList() {
        this.idxMenuData = this.idxMenuList();
      },
      "$i18n.locale"(language) {
        this.changeLang();
      },
    },
    computed: {
      ShowShare() {
        return this.WebSetting.SHOW_SHARE === "1";
      },
      ShowPaySetting() {
        return this.WebSetting.SHOW_PAYSETTING === "1";
      },
      ShowVirtualCurrency() {
        return this.WebSetting.SHOW_VIRTUAL_CURRENCY === "1";
      },
      ShowAccountCard() {
        return this.WebSetting.SHOW_ACCOUNT_CARD === "1";
      },
      ShowConversion() {
        return this.WebSetting.SHOW_CONVERSION === "1";
      },
      ShowSport() {
        let SportData = this.WebSetting.Meun.find(element => element.id == 1);
        return SportData && SportData.products.length > 0;
      },
      ShowLive() {
        let LiveData = this.WebSetting.Meun.find(element => element.id == 2);
        return LiveData && LiveData.products.length > 0;
      },
      ShowEGame() {
        let EGameData = this.WebSetting.Meun.find(element => element.id == 3);
        return EGameData && EGameData.products.length > 0;
      },
      ShowLottery() {
        let LotteryData = this.WebSetting.Meun.find(element => element.id == 4);
        return LotteryData && LotteryData.products.length > 0;
      },
      ShowChess() {
        let ChessData = this.WebSetting.Meun.find(element => element.id == 5);
        return ChessData && ChessData.products.length > 0;
      },
      ShowFish() {
        let FishData = this.WebSetting.Meun.find(element => element.id == 6);
        return FishData && FishData.products.length > 0;
      },
      ShowAnimal() {
        let AnimalData = this.WebSetting.Meun.find(element => element.id == 11);
        return AnimalData && AnimalData.products.length > 0;
      },
      DEF_webStyle() {
        return process.env.DEF_webStyle || 'default';
      },
      IdxFooterList() {
        return this.idxFooter;
        // return this.idxFooter.filter((Item) => {
        //   return Item.url != '/download/APP';
        // }).map((Item) => {
        //   return Item;
        // });
      },
      ShowDownload() {
        let Data = this.idxFooter.find((Item) => Item.url == '/download/APP');
        if (!Data)
          return false;

        if (typeof window == 'undefined' || Object.keys(this.DeviceType).indexOf('type') < 0)
            return false;

        if (this.ShowDownloadApp && this.DeviceType.type == 'ios')
          return Data;

        if (this.ShowDownloadApk && this.DeviceType.type == 'android')
          return Data;

        return false;
      },
      DownloadUrl() {
        if (typeof window == 'undefined' || Object.keys(this.DeviceType).indexOf('type') < 0)
          return '/';

        if (this.ShowDownloadApp && this.DeviceType.type == 'ios') {
          let Code        = '';
          let AgentCode   = this.$store.state.UpAgentCode;
          let CookiesCode = this.$cookies.get("UpAgentCode");
          let StorageCode = localStorage.getItem('UpAgentCode');

          if (StorageCode && StorageCode != null && StorageCode != '')
            Code = StorageCode;
          if (CookiesCode && CookiesCode != null && CookiesCode != '')
            Code = CookiesCode;
          if (AgentCode && AgentCode != null && AgentCode != '')
            Code = AgentCode;

          let DownloadUrl = window.location.protocol + '//' + window.location.host + '/download/APP' + (Code != '' ? '?a=' + Code : '');
          return DownloadUrl;
        }

        if (this.ShowDownloadApk && this.DeviceType.type == 'android') {
          return window.location.protocol + '//' + window.location.host + this.GetDownloadApkUrl;
        }

        return '/';
      },
      ...mapState(['WebSetting','IsLeft', 'IsAudio', 'DeviceType']),
      ...mapGetters('webSetting', ['ShowDownloadApp', 'ShowDownloadApk', 'GetDownloadApkUrl', 'ShowWithdrawCode'])
    }
  };
</script>
