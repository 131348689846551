<!-- idxHotpro11衍生，加入推薦選項 -->
<template>
  <div class="hotgameBox active">
    <div class="hot_s1box">
      <b-tabs class="" nav-wrapper-class="tabListBox">

        <template v-for="(item, index) in TabsData">
          <b-tab>
            <template v-slot:title>
              <span class="typeIcon">
                <i :class="item.class"/>
              </span>
              <span class="typeText">{{ $t(item.title) }}</span>
            </template>
            <div :class="`typeBox typeGame_${item.id}`">
              <ul class="listRow">
                <template v-for="(value, key) in item.tabs">
                  <li :class="[{'listCol': true, 'game-uphold': value.Maintain != '', 'w-100': item.tabs.length <= 5 || value.Id == 'jackpot', 'colJackpot': value.Id == 'jackpot'}]">
                    <div class="item">
                      <template v-if="[6].indexOf(item.id) >= 0" >
                        <div class="itemContent">
                          <div class="itemImg">
                            <img :src="value.ImgPath" class="img-fluid"/>
                          </div>
                          <div class="itemName">{{ value.GameName }}</div>
                          <div class="itemBtns">
                            <button v-show="value.TryGame" type="button" class="playTryBtn" @click="OpenGame(item.id, value, true)">
                              {{ $t("store.btn.free_try_play") }}
                            </button>
                            <button type="button" class="playNowBtn" @click="OpenGame(item.id, value, false)">
                              {{ $t("store.btn.play_game_now") }}
                            </button>
                          </div>
                          <div :class="`itemLogo product_${value.ProductId || ''}`"></div>
                        </div>
                      </template>
                      <template v-else>
                        <a @click="OpenGame(item.id, value, false)">
                          <span class="itemBg">
                            <img :src="require(`@/website/${DEF_webStyle}/images/game/mobile/product-bg.png`)" class="img-fluid"/>
                          </span>
                          <template v-if="[0].indexOf(item.id) >= 0">
                            <span class="itemContent">
                              <span class="itemImg">
                                <img :src="value.ImgPath" class="img-fluid"/>
                              </span>
                              <!-- <a v-show="value.TryGame" @click="OpenGame(item.id, value, true)">
                                {{ $t("store.btn.try_play") }}
                              </a> -->
                              <span class="itemName">{{ value.GameName }}</span>
                              <span :class="`itemLogo product_${value.ProductId || ''}`"></span>
                            </span>
                          </template>
                          <template v-else>
                            <span class="itemContent">
                              <span class="itemImg">
                                <img :src="require(`@/website/${DEF_webStyle}/images/game/mobile/product_${value.Id}.png`)" class="img-fluid"/>
                              </span>
                              <span class="itemName">{{ value.GameName }}</span>
                              <span :class="`itemLogo product_${value.Id}`"></span>
                            </span>
                          </template>

                        </a>
                      </template>

                      <div class="upholdBox">
                        <div class="upholdImg">
                          <img :src="require(`@/website/${DEF_webStyle}/images/game/mobile/product-uphold.png`)" class="img-fluid"/>
                        </div>
                        <div class="upholdText">{{ $t('store.uphold_ing') }}</div>
                      </div>
                    </div>
                  </li>
                </template>
              </ul>
            </div>
          </b-tab>
        </template>

        <template v-if="TabsData.length > 0">
          <b-tab>
            <template v-slot:title>
              <span class="typeIcon">
                <i class="icon icon-recommend" />
              </span>
              <span class="typeText">{{ $t('store.recommend') }}</span>
            </template>
            <div :class="`typeBox typeGame100`">
              <ul class="listRow">
                <li class="listCol" v-for="(item, index) in idxInfo" :key="index">
                  <div class="item">
                    <a v-if="item.blank" :href="item.url" target="_blank">
                      <span class="itemBg">
                        <img :src="require(`@/website/${DEF_webStyle}/images/game/mobile/product-bg.png`)" class="img-fluid"/>
                      </span>
                      <span class="itemContent">
                        <span class="itemImg">
                          <img :src="require(`@/website/${DEF_webStyle}/images/${item.imageUrl}.png`)" class="img-fluid"/>
                        </span>
                        <span class="itemName" v-html="item.gameName"></span>
                      </span>
                    </a>
                    <nuxt-link v-else :to="item.url">
                      <span class="itemBg">
                        <img :src="require(`@/website/${DEF_webStyle}/images/game/mobile/product-bg.png`)" class="img-fluid"/>
                      </span>
                      <span class="itemContent">
                        <span class="itemImg">
                          <img :src="require(`@/website/${DEF_webStyle}/images/${item.imageUrl}.png`)" class="img-fluid"/>
                        </span>
                        <span class="itemName" v-html="item.gameName"></span>
                      </span>
                    </nuxt-link>
                  </div>
                </li>
              </ul>
            </div>
          </b-tab>
        </template>

      </b-tabs>
    </div>
  </div>
</template>

<script>
  import { mapState, mapActions, mapGetters, mapMutations } from "vuex";
  var idxInfo = [];
  try { idxInfo = require("$json/idxInfo.json"); } catch (e) {}
  export default {
    data() {
      return {
        idxInfo,
        TabsData: []
      };
    },
    created() {
    },
    mounted() {
      this.HandleTabsData();
    },
    methods: {
      OpenGame (CategoryId, GameData, IsTry) {
        if ([3].indexOf(CategoryId) >= 0 && GameData.Id == 'jackpot') {
          this.$router.replace({ path: '/mobile/game/jackpot' });

          return;
        }

        if ([3, 7, 8].indexOf(CategoryId) >= 0) {
          if (this.$route.path != '/mobile/game/egame' || !this.$route.query.ProductId || this.$route.query.ProductId != GameData.Id)
            this.$router.replace({ path: '/mobile/game/egame', query: { ProductId: GameData.Id }});

          return;
        }

        // if ([6].indexOf(CategoryId) >= 0) {
        //   if (this.$route.path != '/mobile/game/fish')
        //     this.$router.replace({ path: '/mobile/game/fish' });

        //   return;
        // }

        let Maintain = GameData.Maintain;
        if (Maintain != '')
          return;

        if (this.$auth.$state.loggedIn === false) {
          if (this.$route.path != '/mobile/account/login')
            this.$router.replace({ path: '/mobile/account/login' });

          return;
        }

        if (IsTry) {
          window.open(GameData.UrlTry);
          return;
        }

        if (!this.ShowTransferModal && GameData.WalletCode != 'obchess') {
          window.open(GameData.Url);
          return;
        }

        this.setPlayGameData({ WalletCode: GameData.WalletCode, Url: GameData.Url });
        this.$nextTick(() => this.$root.$emit("bv::toggle::modal", "modalExchPoint", "#btnToggle"));
        return;
      },
      HandleTabsData (CategoryId, GameData, IsTry) {
        if (!this.MobileMeun)
          return [];

        let HotTabs = this.HotGame.map((item, index) => {
          let ImgPath = item.imgPath;
          if ([3, 6, 7, 8].indexOf(item.category_id) < 0)
            ImgPath = `/images/game/hot/product_${item.product_id}.png`;

          return {
            Id: item.id,
            CategoryId: item.category_id,
            GameName: item.name,
            ImgPath: ImgPath,
            Maintain: item.maintain,
            Url: item.playGameUrl,
            UrlTry: item.tryGameUrl,
            WalletCode: item.wallet_code,
            HasTryGame: item.try_game,
          };
        });

        let Data = [
          {
            id: 0,
            class: 'icon icon-hotpro',
            title: 'store.s_hot',
            tabs: HotTabs
          }
        ];
        let $this = this;
        this.MobileMeun.forEach(function(item) {
          // let GameTabs = item.id == '6' ? [{ Id: 'fish', Maintain: '', GameName: $this.$t(item.title) }] : item.tabs;
          let GameTabs = item.tabs;
          if (item.id == '3' && GameTabs[0].Id != 'jackpot')
            GameTabs.splice(0, 0, { Id: 'jackpot', Maintain: '', GameName: '封神榜' });

          Data.push({ id: item.id, class: item.class, title: item.title, tabs: GameTabs });

        });

        this.TabsData = Data;
      },
      ...mapActions("game", ["getPlayGame", "getTryGame"]),
      ...mapMutations("game", ["setPlayGameData"]),
    },

    watch: {
      MobileMeun (value) {
        this.HandleTabsData();
      },
    },
    computed: {
      DEF_webStyle() {
        return process.env.DEF_webStyle || 'default';
      },
      ...mapState(['WebSetting','IsLeft']),
      ...mapGetters(["MobileMeun", "Product", 'HotGame']),
      ...mapGetters('webSetting', ['ShowTransferModal'])
    },
  };
</script>
