<template>
  <div class="m_header">
    <!-- 登入前 -->
    <template v-if="$auth.$state.loggedIn !== true">
      <div class="idx-header logbefore">
        <template  v-if="M_header_ham === '1'">
          <b-button id="show-btn" variant="rmenu" @click="$bvModal.show('bv-modal-example')">
            <i class="icon icon-menu"></i>
            <span>{{ $t("store.hotpro_menu") }}</span>
          </b-button>
        </template>
        <nuxt-link to="/mobile" class="btn-logo"></nuxt-link>

        <!-- <modLogin v-for="(item, index) in LoginBtn" v-model="LoginBtn[index]" :key="index" v-if="item.show" /> -->

        <template v-if="DEF_webStyle === 'sgph'">
          <b-button class="license" @click="showImage = true" >
            License
          </b-button>
          <div class="licensebox" v-if="showImage">
            <div class="license-img">
              <img :src="require(`@/website/${DEF_webStyle}/images/license-1.jpg`)" />
              <button @click="closeImage" class="license-X" >X</button>
            </div>
          </div>
        </template>

        <nuxt-link to="/mobile/account/login" class="login-before btn-login">
          {{ $t("store.btn.login") }}
        </nuxt-link>

        <nuxt-link to="/mobile/account/signup" class="login-before btn-signup">
          {{ this.$t("store.btn.signup") }}
        </nuxt-link>
        <nuxt-link to="/mobile/account/login" class="login-before btn-sign">
          {{ this.$t("store.btn.header_login_signup") }}
        </nuxt-link>

        <template v-if="DEF_webStyle === 'jdf'">
          <audioPlay />
        </template>
        <vuei18n />

      </div>
    </template>
    <!-- 登入後 -->
    <div class="idx-header logafter" v-if="$auth.$state.loggedIn === true">
      <b-button id="show-btn" variant="rmenu" @click="$bvModal.show('bv-modal-example')" v-if="M_header_ham === '1'">
        <i class="icon icon-menu"></i>
        <span>{{ $t("store.hotpro_menu") }}</span>
      </b-button>
      <nuxt-link to="/" class="btn-logo"></nuxt-link>

      <template v-if="DEF_webStyle === 'sgph'">
        <b-button class="license" @click="showImage = true" >
          License
        </b-button>
        <div class="licensebox" v-if="showImage">
          <div class="license-img">
            <img :src="require(`@/website/${DEF_webStyle}/images/license-1.jpg`)" />
            <button @click="closeImage" class="license-X" >X</button>
          </div>
        </div>
      </template>

      <!-- <template v-if="DEF_webStyle === 'sgph'">
        <allBackWalletBtn />
      </template> -->
      <idxLogafterUserInfo v-if="M_header_user === '1'" />
      <div class="login-after" v-if="M_header_btn === '1'">
        <nuxt-link to="/mobile/system/mail" class="btn user-mail">
          <i class="icon icon-mail"></i>
          <span v-if="UnReadCount > 0" class="badge badge-pill badge-danger">{{
              UnReadCount }}</span>
        </nuxt-link>
        </div>
        <template v-if="DEF_webStyle === 'jdf'">
          <audioPlay />
        </template>
        <vuei18n />
        <dialogExchPoint />
        <dialogTransferPoint />
    </div>
  </div>
</template>

<script>
const audioPlay = require(`~/components/${process.env.DEF_audioplay}.vue`).default;
const vuei18n = require(`~/components/${process.env.DEF_language}.vue`).default;
import idxLogafterUserInfo from "~/components/mobile/idxLogafterUserInfo.vue";
import dialogExchPoint from "@/components/mobile/dialogExchPoint.vue";
import dialogTransferPoint from "@/components/desktop/dialogTransferPoint.vue";
import { mapState, mapMutations, mapActions, mapGetters } from "vuex";
import allBackWalletBtn from "~/components/desktop/allBackWalletBtn.vue";
export default {
  components: {
    idxLogafterUserInfo,
    dialogExchPoint,
    dialogTransferPoint,
    vuei18n,
    audioPlay,
    allBackWalletBtn
  },
  data() {
    return {
      form: {
        pw: "",
        name: "",
        food: "",
        checked: [],
      },
      show: true,
      Refresh: null,
      AccountWalletMain: 0,
      AccountWalletMainLoading: false,
      showImage: false,
    };
  },
  created() {
    this.SetAutoRefresh();
    if (this.$auth.$state.loggedIn === true)
      this.getNureadNotice();

    if (Object.keys(this.AccountWallet).indexOf("0") >= 0)
      this.OnAccountWalletChanged();
    if (Object.keys(this.AccountWalletLoading).indexOf("0") >= 0)
      this.OnAccountWalletLoadingChanged();

    this.$watch(() => this.AccountWallet[0], this.OnAccountWalletChanged);
    this.$watch(
      () => this.AccountWalletLoading[0],
      this.OnAccountWalletLoadingChanged
    );
  },
  beforeDestroy() {
    clearInterval(this.Refresh);
  },
  methods: {
    closeImage() {
      this.showImage = false;
    },
    audioClick() {
      if (['jdf', 'fl8n', 'fl88', 'jh8'].indexOf(this.DEF_webStyle) < 0)
        return '';

      if (!this.IsAudio)
        return '';

      try {
        function isIOS() {
          return /iPhone|iPad|iPod/i.test(navigator.userAgent);
        };

        const audio = document.querySelector("#beep");
        const context = new AudioContext();
        if (context.state === 'suspended') {
          context.resume().then(() => {});
        };
        if (isIOS()) {
          setTimeout(function() {
            audio.muted = false;
            audio.play();
          }, 200);
        } else {
          audio.muted = false;
          audio.play();
        };
      } catch (e) {
        console.log(e);
      }
    },
    OnLogout() {
      this.$auth.logout("local");
    },
    RefreshAccountWallet() {
      this.GetAccountWallet(0);
    },
    SetAutoRefresh() {
      if (this.Refresh) clearInterval(this.Refresh);
      this.Refresh = setInterval(() => {
        if (this.$auth.$state.loggedIn === true) this.getNureadNotice();
      }, 60000);
    },
    OnAccountWalletChanged() {
      this.AccountWalletMain = this.AccountWallet[0];
    },
    OnAccountWalletLoadingChanged() {
      this.AccountWalletMainLoading = this.AccountWalletLoading[0];
    },
    init() {
      if (this.ErrorMsg != "") {
        this._showMsgBox({ title: "", msg: this.ErrorMsg }).then((res) => {
          this._setErrorMsg("");
        });
      }
    },
    ...mapActions("account", ["GetAccountWallet"]),
    ...mapActions("webSetting", ["getNureadNotice"]),
    ...mapActions(["_showMsgBox"]),
    ...mapMutations(["_setErrorMsg"]),
  },
  mounted() {
    this.init();
  },
  watch: {
    "$auth.$state.loggedIn"(loggedIn) {
    },
    "$auth.$state.user"(user) {
    },
    'ErrorMsg' (ErrorMsg) {
      if (ErrorMsg && ErrorMsg != '') {
        this._showMsgBox({ title: "", msg: ErrorMsg }).then((res) => {
          this._setErrorMsg("");
        });
      }
    }
  },
  computed: {
    UserName() {
      if (this.WebSetting.MasterAccount === "account")
        return this.$auth.$state.user.account;
      else
        return this.$auth.$state.user.mobile || this.$auth.$state.user.account;
    },
    M_header_btn() {
      return process.env.M_header_btn;
    },
    M_header_ham() {
      return process.env.M_header_ham;
    },
    M_header_user() {
      return process.env.M_header_user;
    },
    DEF_webStyle() {
      return process.env.DEF_webStyle || 'default';
    },
    ...mapState(["WebSetting", 'IsAudio']),
    ...mapGetters(["Meun"]),
    ...mapState("account", ["AccountWallet", "AccountWalletLoading"]),
    ...mapState("webSetting", ["UnReadCount"]),
    ...mapState(['ErrorMsg']),
  },
};
</script>
