<template>
  <div class="m_fab">
    <template v-for="(item, index) in FloatBtnList">
      <div class="float_btn" v-show="CloseAll.indexOf(item.id) < 0 && item.divShow">
        <span class="fab_close" @click.stop="CloseX(item.id)"></span>

        <template v-if="item.type == 'a'">
          <a :id="item.id" :href="item.url" :class="item.btnStyle" target="_blank">
            <div class="fab_default" v-if="item.imgOff">
              <i :class="item.defaultIcon"></i>
              <span>{{ $t(item.defaultName) }}</span>
            </div>
            <div class="fab_img" v-else>
              <img class="img-fluid" :src="require(`@/website/${DEF_webStyle}/images/icon/${item.imgSrc}.webp`)" :alt="item.imgCaption">
            </div>
          </a>
        </template>

        <template v-if="item.type == 'b'">
          <b-button :id="item.id" :to="item.to" :class="item.btnStyle" @click="BtnFlextoggleModal( item.needLogin, item.click[0], item.click[1])">
            <div class="fab_default" v-if="item.imgOff">
              <i :class="item.defaultIcon"></i>
              <span>{{ $t(item.defaultName) }}</span>
            </div>
            <div class="fab_img" v-else>
              <img class="img-fluid" :src="require(`@/website/${DEF_webStyle}/images/mobile/${item.imgSrc}.webp`)" :alt="item.imgCaption">
            </div>
          </b-button>
        </template>

        <template v-if="item.type == 'c'">
          <a :href="WebSetting.LINELINK" :class="item.btnStyle" target="_blank">
            <div class="fab_default" v-if="item.imgOff">
              <i :class="item.iconStyle"></i>
              <span>{{ $t(item.name) }}</span>
              <span class="lind_id" v-if="item.show">{{ WebSetting.LINEID }}</span>
            </div>
            <div class="fab_img" v-else>
              <img class="img-fluid" :src="require(`@/website/${DEF_webStyle}/images/mobile/${item.imgSrc}.webp`)" :alt="item.imgCaption">
            </div>
          </a>
        </template>

        <template v-if="item.type == 'DownloadApp'">
          <b-button :id="item.id" :href="DownloadUrl" :class="item.btnStyle">
            <div class="fab_default" v-if="item.imgOff">
              <i :class="item.defaultIcon"></i>
              <span>{{ $t(item.defaultName) }}</span>
            </div>
            <div class="fab_img" v-else>
              <img class="img-fluid" :src="require(`@/website/${DEF_webStyle}/images/mobile/${item.imgSrc}.webp`)" :alt="item.imgCaption">
            </div>
          </b-button>
        </template>

      </div>
    </template>
  </div>
</template>

<script>
  import { getJsonFile } from '~/utils/jsonFile';
  import { mapState, mapActions, mapGetters, mapMutations } from "vuex";
  export default {
    name: "m_FAB",
    data() {
      return {
        FloatBtnJson: null,
      };
    },
    created() {
      if (this.FloatBtnJson == null  || this.FloatBtnJson.length == 0)
        this.FloatBtnJson = getJsonFile(process.env.DEF_webStyle, 'm_floatBtn');
    },
    methods: {
      CloseX(id) {
        this._setCloseAll(id);
      },
      BtnFlextoggleModal(status, modal, btnTag) {
        if (modal == "off")
          return ;
        if (status && this.$auth.$state.loggedIn === false)
          this.$router.push({ name: 'account-login', params: null, query: null });
        else
          this.$root.$emit("bv::toggle::modal", modal, btnTag);
      },
      ...mapMutations(['_setCloseAll']),
    },
    computed: {
      DEF_webStyle() {
        return process.env.DEF_webStyle || 'default';
      },
      FloatBtnList() {
        if (!this.FloatBtnJson)
          return [];

        let ShowDownload = false;
        if (typeof window !== 'undefined' && Object.keys(this.DeviceType).indexOf('type') >= 0) {
          if (this.ShowDownloadApp && this.DeviceType.type == 'ios')
            ShowDownload = true;
          if (this.ShowDownloadApk && this.DeviceType.type == 'android')
            ShowDownload = true;
        }

        return this.FloatBtnJson.map(Item => {
          let divShow = Item.type == 'DownloadApp' ? ShowDownload : true;
          return {
            divShow,
            ...Item
          };
        })
      },
      DownloadUrl() {
        if (typeof window == 'undefined' || Object.keys(this.DeviceType).indexOf('type') < 0)
          return '/';

        if (this.ShowDownloadApp && this.DeviceType.type == 'ios') {
          let Code        = '';
          let AgentCode   = this.$store.state.UpAgentCode;
          let CookiesCode = this.$cookies.get("UpAgentCode");
          let StorageCode = localStorage.getItem('UpAgentCode');

          if (StorageCode && StorageCode != null && StorageCode != '')
            Code = StorageCode;
          if (CookiesCode && CookiesCode != null && CookiesCode != '')
            Code = CookiesCode;
          if (AgentCode && AgentCode != null && AgentCode != '')
            Code = AgentCode;

          let DownloadUrl = window.location.protocol + '//' + window.location.host + '/download/APP' + (Code != '' ? '?a=' + Code : '');
          return DownloadUrl;
        }

        if (this.ShowDownloadApk && this.DeviceType.type == 'android') {
          return window.location.protocol + '//' + window.location.host + this.GetDownloadApkUrl;
        }
        
        return '/';
      },
      ...mapState(['WebSetting', 'CloseAll', 'DeviceType']),
      ...mapGetters('webSetting', ['ShowDownloadApp', 'ShowDownloadApk', 'GetDownloadApkUrl'])
    }
  };
</script>
