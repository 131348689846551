<template>
  <div :class="['idex-wrap', 'mobile', FestivalClass, LocaleClass]">
    <button id="sound_btn" class="ignore ignore2" @click="sound_btn"></button>
    <pageHead device="mobile" page="layouts"/>
    <div id="livechat" class="onlinechat dn" v-if="HasLivechat">
      <a href="#" class="chat-close" @click="closeModal"></a>
    </div>
    <div class="idx-main">
      <MHeader />
      <div class="idx-content">
        <main @scroll="loadMore" ref="ul" class="index_hot">
          <slot name="content">
            <pageHead device="mobile" page="index"/>
            <idxMain01 />
            <idxMain02 />
            <idxMain03 />
            <idxMain04 />
            <idxMain05 />
            <idxMain06 />
            <idxFooter />
          </slot>
          <idxHideMenu />
        </main>

      </div>
    </div>
    
    <idxFmenu />

    <userFloatBtn />

    <audio id="beep" src="/audio/btnClick.mp3" type="audio/mp3" muted></audio>

    <div class="landscape" :class="{on:maskOn}" v-html='$t("store.landscape")' ref="mask"></div>
    <overlay v-model="IsLoading"></overlay>
    <upAgentCode />
    <fingerprintJS/>
    <slot name="other"/>
  </div>
</template>
<script>
const idxMain01 = require(`~/components/mobile/${process.env.M_main01}.vue`).default;
const idxMain02 = require(`~/components/mobile/${process.env.M_main02}.vue`).default;
const idxMain03 = require(`~/components/mobile/${process.env.M_main03}.vue`).default;
const idxMain04 = require(`~/components/mobile/${process.env.M_main04}.vue`).default;
const idxMain05 = require(`~/components/mobile/${process.env.M_main05}.vue`).default;
const idxMain06 = require(`~/components/mobile/${process.env.M_main06}.vue`).default;
const idxFooter = require(`~/components/mobile/${process.env.M_Footer}.vue`).default;
const idxHideMenu = require(`~/components/mobile/${process.env.M_HideMenu}.vue`).default;

import { getJsonFile } from '~/utils/jsonFile';
import { mapState, mapActions, mapGetters, mapMutations } from "vuex";
import pageHead from "~/components/common/pageHead.vue";
import userFloatBtn from "~/components/mobile/header/m_FAB01.vue";
import MHeader from "~/components/mobile/header/m_Header01.vue";
import idxFmenu from "~/components/mobile/header/m_Fmenu.vue";
import overlay from "@/components/common/overlay.vue";
import upAgentCode from "@/components/common/upAgentCode.vue";
import fingerprintJS from "@/components/common/fingerprintJS.vue";
export default {
  components: {
    userFloatBtn,
    pageHead,
    MHeader,
    idxMain01,
    idxMain02,
    idxMain03,
    idxMain04,
    idxMain05,
    idxMain06,
    idxFooter,
    idxFmenu,
    idxHideMenu,
    overlay,
    upAgentCode,
    fingerprintJS
  },
  data(){
    return{
      maskOn: false,
      perpage1: 12,
      height1: 0,
      scroll1: 0,
      bottom1: 0,
      LocaleClass: 'lang-'
    }
  },
  mounted() {
    window.addEventListener("resize", this.renderRwsize, false);
    if(this.M_dialogAPP !== '0'){
      if (this.IsIos)
        this.$root.$emit("bv::toggle::modal", "modalAppiconIOS");
      if (this.IsAndroid)
        this.$root.$emit("bv::toggle::modal", "modalAppiconAndroid");
    }
    this.height();
    this.$nextTick(function () {
      const sound_btn = document.getElementById('sound_btn');
      if (sound_btn) {
        sound_btn.click();
      }
    });

    this.$nextTick(function () {
      this.LocaleClass = 'lang-' + this.$i18n.locale;
    });
  },
  beforeDestroy() {
    window.removeEventListener("resize", this.renderRwsize, false);
  },
  methods: {
    loadMore(){
      if(this.DEF_webStyle === 'sgph'){
        let ul      = this.$refs.ul;
        let height = ul.clientHeight;
        let scroll = Math.ceil(ul.scrollTop);
        let bottom = ul.scrollHeight;
        if (bottom - scroll > height)
          return;

        this._setGameLoadding(this.GameLoadding+1);

        // if (this.TableSetting.PageSetting.Total > this.List.length) {
        //   this.load = true;
        //   this.Current++;
        //   setTimeout(e => { this.load = false; }, 500);
        // }
      }
    },
    sound_btn(){
      if (this.DEF_webStyle === 'jdf')
      {
        try {
          const btnLinks = document.querySelectorAll("button, a, ul.navbar, textarea, .overflow-auto, .game_leftbtn, input, select, .close, .mq-cont.as1, .user-wallet, .sound_on, .mb_gotop");
          const audio = document.querySelector("#beep");
          const context = new AudioContext();
          function isIOS() {
            return /iPhone|iPad|iPod/i.test(navigator.userAgent);
          };
          btnLinks.forEach((btnLink) => {
            if (btnLink.classList.contains("ignore")) {
              return;
            };
            btnLink.removeEventListener("click", () => {
              if(this.IsAudio == true){
                if (context.state === 'suspended') {
                  context.resume().then(() => {});
                };
                if (isIOS()) {
                  setTimeout(function() {
                    audio.muted = false;
                    audio.play();
                  }, 200);
                } else {
                  audio.muted = false;
                  audio.play();
                };
              };
            });
            btnLink.addEventListener("click", () => {
              if(this.IsAudio == true){
                if (context.state === 'suspended') {
                  context.resume().then(() => {});
                };
                if (isIOS()) {
                  setTimeout(function() {
                    audio.muted = false;
                    audio.play();
                  }, 200);
                } else {
                  audio.muted = false;
                  audio.play();
                };
              };
            });
          });
        } catch (e) {
          console.log(e);
        }
      };
    },
    height(){
      let vh = window.innerHeight * 0.01;
      document.documentElement.style.setProperty('--vh', `${vh}px`);
    },
    scrollGoTop(){
      window.scrollTo({ top: 0 });
    },
    closeModal() {
      document.getElementById("livechat").classList.remove("show");
    },
    renderRwsize(){
      let winW = window.innerWidth;
      let winH = window.innerHeight;
      if (typeof window.orientation !== 'undefined') {
        if (winH < winW && document.activeElement.tagName !== 'INPUT' && document.activeElement.tagName !== 'TEXTAREA') {
          this.maskOn = true;
        } else {
          this.maskOn = false;
        }
      }
      let vh = window.innerHeight * 0.01;
      document.documentElement.style.setProperty('--vh', `${vh}px`);
      this.height();
    },
    ...mapMutations(['_setGameLoadding'])
  },
  computed: {
    DEF_webStyle() {
      return process.env.DEF_webStyle || 'default';
    },
    M_dialogAPP(){
      return process.env.M_dialogAPP;
    },
    IsIos() {
      if (Object.keys(this.DeviceType).indexOf('type') < 0 || this.DeviceType.type != 'ios')
        return false;
      else if (localStorage.getItem('checkIos'))
        return false;
      else
        return true;
    },
    IsAndroid() {
      if (Object.keys(this.DeviceType).indexOf('type') < 0 || this.DeviceType.type != 'android')
        return false;
      else if (localStorage.getItem('checkAndroid'))
        return false;
      else
        return true;
    },
    HasLivechat() {
      return Object.keys(this.WebSetting).indexOf('LIVECHAT') && this.WebSetting.LIVECHAT;
    },
    ...mapState(['WebSetting', 'IsAudio', 'IsLoading', 'GameLoadding']),
    ...mapState(['IsMobile', 'DeviceType']),
    ...mapGetters('webSetting', ['FestivalClass'])
  },
  watch: {
    "$route.path": function(path) {
      if(path !== '/'){
        this.scrollGoTop();
      }
    },
    "$i18n.locale": function(test) {
      this.LocaleClass = 'lang-' + this.$i18n.locale;
    }
  }
};
</script>
<style>

@media only screen and (max-width: 820px) {
  .my-element {
    height: 100vh;
    height: calc(var(--vh, 1vh) * 100);
  }
}

</style>
